/* Normal desktop :1200px. */
@media (min-width:1200px){
}

/* Normal desktop :992px. */
@media (min-width:992px){
    .col-1-5{-ms-flex:0 0 20%;flex:0 0 20%;max-width:20%;}
    .col-2-5{-ms-flex:0 0 40%;flex:0 0 40%;max-width:40%;}
    .col-3-5{-ms-flex:0 0 60%;flex:0 0 60%;max-width:60%;}
    .col-4-5{-ms-flex:0 0 80%;flex:0 0 80%;max-width:80%;}
    .mb-lg-30{margin-bottom:30px!important;}
    .main-search-form-cover{padding:10px 100px 50px 100px;}
    p.suggested{margin-left:50px;}
}

/* Tablet desktop :768px. */
@media (min-width:768px){
    .header-style-1 .header-bottom{display:block;height:70px;}
    .slicknav_nav li{display:block;max-width:720px;margin:0 auto;position:relative;}
    .main-search-form p.ml-50{margin-left:0!important;}
    .main-search-form .search-form input{font-size:18px;}
    .main-search-form .search-form input{font-size:22px;}
}

/* small mobile :992px. */
@media (max-width:992px){
    .widget-area.pl-30{padding-left:0!important;}
    .nav-shopping-cart a.cart-icon{right:95px;z-index:999;}
    .header-style-2 .off-canvas-toggle-cover{right:unset;left:0;}
    .header-style-2 #off-canvas-toggle{margin-left:0;}
    .header-style-2 .nav-shopping-cart a.cart-icon{right:30px;}
    .header-style-2 .main-header .search-button{right:60px;}
    .header-style-2 .slicknav_menu .slicknav_icon{margin:0 50px 0 0;}
    .featured-slider-2 .post-content-overlay{top:100px;}
}

/* small mobile :768px. */
@media (max-width:768px){
    .mb-md-30{margin-bottom:30px!important;}
    .follow-us{display:block!important}
    .follow-us a{width:100%;margin:0 0 15px 0!important;}
    .post-module-4 .img-hover-slide{height:493px;}
    .footer-bottom-area{text-align:center;}
    #off-canvas-toggle{margin-left:25px!important;}
    .main-header .search-button{right:45px;}
    .header-style-3 .off-canvas-toggle-cover{left:0;}
    .news-flash-cover{float:none!important;}
    .loop-list .post-thumb.mr-20{margin-right:0!important;}
    .page-404{text-align:center;}
    .entry-header-1 h1{max-width:100%}
    .single-header-2 .entry-header-1{padding:50px;}
    .nav-shopping-cart a.cart-icon{right:80px;}
}

/* Large Mobile :480px. */
@media only screen and (max-width:480px){
    .canvas-opened #sidebar-wrapper{width:100%;}
    .block-tab-item .post-block-style,
    .block-tab-item .sm-grid-content{margin-bottom:30px;}
    .widget-title{text-align:center;}
    .letter-background{left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);}
    .tab-nav.style-1 .nav-link{padding:4px 7px;margin:0 1px;}
    .tab-nav.style-1{margin-bottom:30px;}
    .footer-bottom-area .footer-copy-right p{line-height:1.5;}
    .post-module-4 .img-hover-slide{height:345px;margin-bottom:30px;}
    .video-area .letter-background,
    .tab-nav.style-1 i{display:none;}
    .video-area .block-tab-item{margin-top:30px!important;}
    .video-area .widget-title{text-align:left;}
    .loop-list .post-thumb,
    .pagination-area{margin-bottom:30px;}
    .main-search-form .search-switch{font-size:12px;}
    .suggested-area{text-align:center;}
    .widget-taber a.nav-link.active{font-size:14px;padding:4px 15px 0 15px;}
    .author-bio{display:block;}
    .single-social-share{text-align:left;}
    .single-social-share .entry-meta{width:100%;margin-bottom:15px;}
    .single-social-share ul{width:100%}
    .single-header-2 .entry-header-1,
    .single-header-3.single-header-2 .entry-header-1{padding:30px 15px;}
    .single-header-2 .entry-header-1 h1{font-size:1.5rem}
    .single-header-2 .single-thumnail{min-height:340px;}
    .nav-shopping-cart a.cart-icon span.cart_count,
    .featured-slider-2-nav{display:none;}
    .featured-slider-2 .post-content-overlay .post-title{max-width:100%;}
    .shopping-cart .total-amount .left .coupon form input{width:100%;border-radius:3px;margin-bottom:15px;}
    .shopping-cart .total-amount .left .coupon form .btn{border-radius:3px;margin:0;}
    .archive-header{text-align:center;}
    .shopping-summery tbody .price,
    .shopping-summery tbody .total-amount,
    .shopping-summery tbody .action{text-align:right;}
    .shopping-summery .detail-qty{float:right;}
}

/*Other*/
@media (max-width:767px){
    #scrollUp{right:16px}
    .sticky-logo{display:block!important}
    .recent-articles .single-recent .what-cap h4{font-size:13px}
    .social-share ul li{margin:0 7px}
    .comment-form .name{padding-right:0px;margin-bottom:1rem}
    .shop_cart_table td,
    .wishlist_table .table td{display:block;width:100%;text-align:center;}
    .shop_cart_table thead,
    .wishlist_table thead{display:none;}
    .shop_cart_table td::before,
    .wishlist_table td::before{content:attr(data-title) " ";float:left;text-transform:capitalize;margin-right:15px;font-weight:bold;}
    .shop_cart_table td.product-quantity::before{margin-top:10px;}
    .shop_cart_table td.product-thumbnail,
    .shop_cart_table td.product-add-to-cart,
    .wishlist_table td.product-thumbnail,
    .wishlist_table td.product-add-to-cart{text-align:center;}
    .shop_cart_table .quantity{-ms-flex-pack:end;justify-content:flex-end;}
    .input-group-append .btn{min-width:unset;}
}
@media only screen and (min-width:768px) and (max-width:991px){
    .header-sticky.sticky-bar.sticky .main-nav ul>li>a{padding:33px 13px}
    .header-sticky.sticky-bar.sticky .main-nav .sub-menu{right:0;left:inherit}
}
@media only screen and (min-width:576px) and (max-width:767px){
    .sticky-logo{display:block!important}
}